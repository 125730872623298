var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"shop-product-search-simple__result_custom\">\r\n        <div class=\"result__product-name\">\r\n            <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"product_name") || (depth0 != null ? lookupProperty(depth0,"product_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"product_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":36}}}) : helper)))
    + "</span>\r\n            <span class=\"text-muted\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"custom_product_not_from_the_catalog",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":95}}}))
    + "</span>\r\n        </div>\r\n    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFirst") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":91,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"shop-product-search-simple__result\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSku") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"result__product-name\">\r\n                "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"product_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":51}}}))
    + "\r\n            </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showStock") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showImage") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"result__product-number\">\r\n                    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"product_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":57}}}))
    + "\r\n                </div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <div  class=\"result__product-price\">\r\n                    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"unit_price",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":53}}}))
    + "\r\n                </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"result__product-stock\">\r\n                    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"stock",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":48}}}))
    + "\r\n                </div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"result__product-image\">\r\n                    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"image",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":48}}}))
    + "\r\n                </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"shop-product-search-simple__result\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSku") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":41,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"result__product-name\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showProductType") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":50,"column":23}}})) != null ? stack1 : "")
    + "                "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\r\n            </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":79,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showStock") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":12},"end":{"line":84,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showImage") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":89,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"result__product-number\">\r\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"product") : stack1)) != null ? lookupProperty(stack1,"sku") : stack1), depth0))
    + "\r\n                </div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":46,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"productTypeName") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":49,"column":27}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"label label-danger\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"inactive",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":57},"end":{"line":45,"column":88}}}))
    + "</span>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"label label-info\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"productTypeName") || (depth0 != null ? lookupProperty(depth0,"productTypeName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"productTypeName","hash":{},"data":data,"loc":{"start":{"line":48,"column":55},"end":{"line":48,"column":74}}}) : helper)))
    + "</span>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div  class=\"result__product-price\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"effective_list_price") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":77,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPricesWithTax") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":60,"column":31}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"effective_list_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"effective_list_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":57,"column":28},"end":{"line":57,"column":116}}}))
    + "\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"effective_list_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"effective_list_price") : depth0)) != null ? lookupProperty(stack1,"ppu") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":59,"column":28},"end":{"line":59,"column":113}}}))
    + "\r\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPricesWithTax") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":62,"column":24},"end":{"line":76,"column":31}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$compare","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":63,"column":28},"end":{"line":68,"column":47}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":64,"column":32},"end":{"line":64,"column":114}}}))
    + "\r\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <s class=\"discount-original-price\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":66,"column":67},"end":{"line":66,"column":149}}}))
    + "</s>\r\n                                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":67,"column":32},"end":{"line":67,"column":106}}}))
    + "\r\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"ppu") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu") : stack1),{"name":"common$compare","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":70,"column":28},"end":{"line":75,"column":47}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":71,"column":32},"end":{"line":71,"column":111}}}))
    + "\r\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <s class=\"discount-original-price\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_default_price") : depth0)) != null ? lookupProperty(stack1,"ppu") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":73,"column":67},"end":{"line":73,"column":146}}}))
    + "</s>\r\n                                "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"ppu") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":74,"column":32},"end":{"line":74,"column":103}}}))
    + "\r\n";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"result__product-stock\">\r\n                    <span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stockCls") || (depth0 != null ? lookupProperty(depth0,"stockCls") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stockCls","hash":{},"data":data,"loc":{"start":{"line":82,"column":33},"end":{"line":82,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stockName") || (depth0 != null ? lookupProperty(depth0,"stockName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stockName","hash":{},"data":data,"loc":{"start":{"line":82,"column":47},"end":{"line":82,"column":60}}}) : helper)))
    + "</span>\r\n                </div>\r\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"result__product-image\">\r\n                    <div class=\"result__product-image__image\" style=\"background-image: url('"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"main_image") : stack1)) != null ? lookupProperty(stack1,"url") : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.program(43, data, 0),"data":data,"loc":{"start":{"line":87,"column":92},"end":{"line":87,"column":211}}})) != null ? stack1 : "")
    + "')\"></div>\r\n                </div>\r\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\upx\\templates\\helpers\\server.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"main_image") : stack1)) != null ? lookupProperty(stack1,"url") : stack1),{"name":"upx$server","hash":{},"data":data,"loc":{"start":{"line":87,"column":127},"end":{"line":87,"column":169}}}));
},"43":function(container,depth0,helpers,partials,data) {
    return "images/no-product-image.png";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fromChoice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":92,"column":7}}})) != null ? stack1 : "");
},"useData":true});