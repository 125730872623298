var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":6,"column":29},"end":{"line":6,"column":44}}}) : helper)))
    + "\"\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                placeholder=\""
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"product_search",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":29},"end":{"line":8,"column":66}}}))
    + "\"\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product-search\" data-action=\"product-search\" data-ui=\"product-search\">\r\n    <input\r\n            type=\"text\"\r\n            disabled\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeholder") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "            data-ui=\"product-search-input\"\r\n            value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"currentValue") || (depth0 != null ? lookupProperty(depth0,"currentValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currentValue","hash":{},"data":data,"loc":{"start":{"line":11,"column":19},"end":{"line":11,"column":35}}}) : helper)))
    + "\">\r\n    <i class=\"fas fa-search\"></i>\r\n</div>\r\n\r\n<div data-region=\"popupSearchInput\"></div>\r\n<div data-region=\"popup\"></div>";
},"useData":true});